import React from "react";
import { css, Styled } from "theme-ui";
import SEO from "../components/seo";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";

function About() {
  const data = useStaticQuery(query);
  const { launch, newly, lumera } = data;

  return (
    <Layout>
      <SEO title="About Me" />

      <section css={css({ mb: 4 })}>
        <Styled.h1 css={css({ fontSize: 4 })}>
          Hi, it's nice to meet you!
        </Styled.h1>
        <p>
          My name is Brandon, and I am a freelance web developer from Southern
          California. I love learning about, and writing code in, many
          programming languages.
        </p>
      </section>

      <section css={css({ mb: 4 })}>
        <Styled.h2 css={css({ fontSize: 3 })}>
          Would you like to see some of my work?
        </Styled.h2>

        <div css={css({ mb: 3 })}>
          <Styled.a
            css={css({
              float: "left",
              mr: 2,
            })}
            href="https://launchschool.com"
            target="_blank"
          >
            <Image
              fixed={launch.childImageSharp.fixed}
              alt="Launch School - An online school for engineers"
              css={css({
                mb: 0,
              })}
            />
          </Styled.a>

          <p>
            Not only was I student in 2013 and 2014, I have been working for{" "}
            <Styled.a href="https://launchschool.com" target="_blank">
              Launch School
            </Styled.a>{" "}
            ever since. If you are looking for a solid education in the field of
            web development, check them out!
          </p>
        </div>

        <div css={css({ mb: 3 })}>
          <Styled.a
            css={css({
              float: "left",
              mr: 2,
            })}
            href="https://www.lumeradiamonds.com"
            target="_blank"
          >
            <Image
              fixed={lumera.childImageSharp.fixed}
              alt="Lumera Diamonds - Engagement Rings and Loose Diamonds"
              css={css({
                mb: 0,
              })}
            />
          </Styled.a>

          <p>
            I have been maintaining and building features on{" "}
            <Styled.a href="https://www.lumeradiamonds.com" target="_blank">
              LumeraDiamonds.com
            </Styled.a>{" "}
            since 2014. The site recently underwent an update and gained the
            ability for users to purchase settings and bands along with a
            diamond.
          </p>
        </div>

        <div>
          <Styled.a
            css={css({
              float: "left",
              mr: 2,
            })}
            href="https://newlywords.com"
            target="_blank"
          >
            <Image
              fixed={newly.childImageSharp.fixed}
              alt="Newlywords - Photobooks created by communities"
              css={css({
                mb: 0,
              })}
            />
          </Styled.a>

          <p>
            I have been maintaining and building features on{" "}
            <Styled.a href="https://newlywords.com" target="_blank">
              Newlywords
            </Styled.a>{" "}
            since 2015. If you are looking for a creative and memorable gift or
            momento, check it out!
          </p>
        </div>
      </section>

      <section>
        <Styled.h2 css={css({ fontSize: 3 })}>
          I have many hobbies, here are a few:
        </Styled.h2>

        <dl>
          <dt>Wood Working</dt>
          <dd>
            I enjoy designing my projects in{" "}
            <Styled.a
              href="https://www.sketchup.com/plans-and-pricing/sketchup-free"
              target="_blank"
            >
              SketchUp
            </Styled.a>
            , then creating them in my garage.
          </dd>

          <dt>Watching TV</dt>
          <dd>
            I'm not a big fan of movies, but I really enjoy watching
            long-running TV series'.
          </dd>

          <dt>Four-Wheel Drive Vehicles</dt>
          <dd>
            As long as I have had vehicles, I have been driving them offroad. I
            previously built a{" "}
            <Styled.a href="http://hendrixmotorsports.com/" target="_blank">
              {" "}
              Hendrix Motorsports
            </Styled.a>{" "}
            <Styled.a
              href="http://hendrixmotorsports.com/id4.html"
              target="_blank"
            >
              {" "}
              Formula Toy
            </Styled.a>
            , which we would use in Johnson Valley, CA, USA. If you have never
            heard of "The Hammers", or "King of the Hammers", check out the
            video below. I have spent many nights on these trails helping
            friends fix their broken rigs. Good times!
            <Styled.div css={css({ maxWidth: 500, mx: "auto" })}>
              <Styled.div
                css={css({
                  my: 4,
                  position: "relative",
                  width: "100%",
                  height: 0,
                  paddingBottom: "56.25%",
                })}
              >
                <iframe
                  src="https://www.youtube.com/embed/AlCkwrWT3Wo"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  title="King of the Hammers 2020 Highlights Video"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></iframe>
              </Styled.div>
            </Styled.div>
          </dd>
        </dl>
      </section>
    </Layout>
  );
}

export default About;

export const query = graphql`
  query aboutQuery {
    launch: file(
      absolutePath: { regex: "/launch-school.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    newly: file(absolutePath: { regex: "/newlywords.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lumera: file(
      absolutePath: { regex: "/lumera-diamonds.(jpeg|jpg|gif|png)/" }
    ) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
